import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import * as Icon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import ReportModal from './ReportModal';

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState('');
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const location = useLocation();

  const data = useSelector((state) => state.login.user);
  const validationRoles = data?.validationRoles || [];
  const isReporter = validationRoles.some(role => role.name === 'Statistics Reporter');

  const toggleMenuState = (menuState) => {
    setActiveMenu((prevState) => (prevState === menuState ? '' : menuState));
  };

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    resetMenusBasedOnPath();
  };

  const resetMenusBasedOnPath = () => {
    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/users', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/editors', state: 'editorsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];
    const activeMenu = dropdownPaths.find(p => location.pathname.startsWith(p.path))?.state || '';
    setActiveMenu(activeMenu);
  };

  useEffect(() => {
    onRouteChanged();
    // Add hover functionality for sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, [location]);

  const renderNavItem = (path, icon, title, subMenuItems = []) => {
    const isActive = location.pathname.startsWith(path);
    const isOpen = activeMenu === `${path}Open`;
    const isHovered = hoveredMenu === path;

    return (
      <li className={`nav-item ${isActive ? 'active' : ''} ${isHovered ? 'hover-open' : ''}`}
        onMouseEnter={() => setHoveredMenu(path)}
        onMouseLeave={() => setHoveredMenu(null)}>
        <div onClick={() => toggleMenuState(`${path}Open`)}
          className={`nav-link ${isOpen ? 'menu-expanded' : ''}`}>
          <Link className="nav-link p-0 m-0" to={path}>
            {icon}
            <span className="menu-title">{title}</span>
            {subMenuItems.length > 0 && <i className="menu-arrow"></i>}
          </Link>
        </div>
        {subMenuItems.length > 0 && (
          <Collapse in={isOpen}>
            <ul className="nav flex-column sub-menu">
              {subMenuItems.map((item) => (
                <li key={item.path} className={`nav-item ${location.pathname.startsWith(item.path) ? 'active' : ''}`}>
                  <Link className="nav-link" to={item.path}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </Collapse>
        )}
      </li>
    );
  };

  return (
    <>
      <nav className="sidebar sidebar-offcanvas sidebar-boxshadow" id="sidebar"
        onTouchMove={() => document.body.classList.toggle('sidebar-icon-only')}>
        <ul className="nav pt-2">
          {renderNavItem('/home', <Icon.Home className="menu-icon" />, 'Home')}
          {renderNavItem('/dashboard', <Icon.Target className="menu-icon" />, 'My Actions')}

          {/* Conditional Rendering based on roles and permissions */}
          {validationRoles.some(role => ['India (IN)', 'UK (UK)', 'Singapore (SG)', 'Thailand (TH)', 'Philippines (PH)', 'Korea (KR)', 'Indonesia (ID)', 'Japan (JP)'].includes(role.name)) &&
            renderNavItem('/users', <Icon.User className="menu-icon" />, 'User Configuration', [
              { path: '/users/user', title: 'Internal Users' },
              { path: '/users/app-user', title: 'External Users' },
              { path: '/users/master-user', title: 'User Master Data' }
            ])
          }

          {/* Applications with submenus */}
          {renderNavItem('/apps', <Icon.Layers className="menu-icon" />, 'Applications', [
            { path: '/apps/ehs', title: 'EHS Observation' },
            { path: '/apps/eptw', title: 'ePermit to Work' },
            { path: '/apps/incident', title: 'Incident Reporting' },
            { path: '/apps/inspection', title: 'Inspection' },
            { path: '/apps/audit', title: 'Audit' },
            { path: '/apps/equipment', title: 'Plant & Equipment' },
            ...isReporter ? [{ path: '/apps/reports', title: 'EHS Statistics' }] : []
          ])}

          {renderNavItem('/bug_report', <Icon.AlertCircle className="menu-icon" />, 'Report an Issue')}
          {renderNavItem('/role-user', <Icon.Users className="menu-icon" />, 'View Assignment Report')}
          {renderNavItem('/logout', <Icon.LogOut className="menu-icon" />, 'Logout')}
        </ul>
      </nav>
      {showReportModal && <ReportModal hideModal={() => setShowReportModal(false)} />}
    </>
  );
};

export default Sidebar;
